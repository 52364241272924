// Vendors
import * as yup from 'yup';

const REQUIRED_MESSAGE = 'This field is required';

function validateMmatFileExtension(files: File[]): boolean {
  const validation = files.every(file => {
    const splitted = file.name.split('.');
    const extension = splitted[splitted.length - 1];
    const acceptedExtensions = [
      'docx',
      'DOCX',
      'doc',
      'DOC',
      'pdf',
      'PDF',
      'xls',
      'XLS',
      'xlsx',
      'XLSX',
      'PNG',
      'png',
      'JPG',
      'jpg',
      'JPEG',
      'jpeg',
    ];
    return acceptedExtensions.includes(extension);
  });
  return validation;
}

export const validationSchema = yup.object().shape({
  files: yup
    .array()
    .required(REQUIRED_MESSAGE)
    .test(
      '',
      'The file has an invalid extension',
      value => value && value.length && validateMmatFileExtension(value)
    ),
  documentType: yup
    .object()
    .shape({ label: yup.string(), value: yup.string() })
    .required(REQUIRED_MESSAGE),
  audienceType: yup
    .array()
    .nullable()
    .of(yup.object().shape({ label: yup.string(), value: yup.string() }))
    .required(REQUIRED_MESSAGE),
  isInvolved: yup.boolean().required(REQUIRED_MESSAGE),
  isCreatedByYourself: yup.boolean().required(REQUIRED_MESSAGE),
  explainDocument: yup.string().when('isCreatedByYourself', {
    is: isCreatedByYourself => isCreatedByYourself === false,
    then: yup.string().required(REQUIRED_MESSAGE),
  }),
});
