import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';
import get from 'lodash/get';
import useStateModal from 'app/utils/hooks/useStateModal';

// mutations
import { CREATE_FORM } from 'api/graphql/compliance/mutations';

// context
import { AlertContext } from 'app/notifications/context/Alert/Alert';
import { DealsContext } from 'app/compliance/context/DealsContext';
import { FormsContext } from 'app/compliance/context/FormsContext';

import { FormType } from '../../../../../../types/graphql-types';

// hooks
import { FormikStub, useForm } from 'app/utils/hooks/useForm';

// validation schema
import { validationSchema } from './MarketingMaterialsFormValidation';
import { MMAT_FORM_LABELS } from './MarketingMaterialsFormConstants';

export type SelectedValue = {
  value: string;
  label: string;
};

export type MarketingMaterialForm = {
  documentType: SelectedValue | null;
  audienceType: SelectedValue[] | null;
  isInvolved: boolean | null;
  isCreatedByYourself: boolean | null;
  explainDocument?: string;
  additionalNotes: string;
  files: File[];
};

const initialValues = {
  documentType: null,
  audienceType: null,
  isInvolved: null,
  isCreatedByYourself: null,
  explainDocument: '',
  additionalNotes: '',
  files: [],
};

export interface UseMarketingMaterialFormHandler {
  form: FormikStub<MarketingMaterialForm>;
  onSubmitMarketingMaterialForm: () => Promise<void>;
  submitting: boolean;
}

export const MarketingMaterialHandlerContext = React.createContext<UseMarketingMaterialFormHandler>(
  {} as UseMarketingMaterialFormHandler
);
export const MarketingMaterialHandlerProvider = MarketingMaterialHandlerContext.Provider;

export const useMarketingMaterialFormHandler = (): UseMarketingMaterialFormHandler => {
  const { showErrorToast, showSuccessToast } = useContext(AlertContext);
  const { refetchAll } = useContext(FormsContext);
  const { selectedDeal: dealId } = useContext<any>(DealsContext);
  const { hide, modalState } = useStateModal('MarketingMaterialsFormDrawer');

  const form = useForm<MarketingMaterialForm>({
    initialValues,
    validationSchema,
  });

  const regulatory = get(modalState, 'props.isRegulatory', false);

  const { resetForm } = form;

  const onSuccess = () => {
    hide();
    resetForm();
    if (regulatory) {
      refetchAll(['regulatoryProfile', 'MMRCount']);
    } else {
      refetchAll(['complianceTracker', 'dealRegulation']);
    }
  };

  const [createFormMutation, { loading: loadingForm }] = useMutation(CREATE_FORM, {
    fetchPolicy: 'no-cache',
    onCompleted() {
      showSuccessToast({ title: 'Success!', description: 'Form was created successfully' });
      onSuccess();
    },
    onError() {
      showErrorToast({ title: 'Error', description: 'An error occurred while saving the form' });
    },
  });

  const submitting = loadingForm;

  const buildAudienceTypeList = (
    audienceType: { label: string; value: string }[] | null
  ): string => {
    if (audienceType) {
      const audienceList = audienceType.map(option => option.label).join(', ');
      return audienceList;
    }
    return '';
  };

  const buildMMForm = (props: MarketingMaterialForm, dealId: string) => {
    const mapYesNoToString = (value: boolean | null): string => (value ? 'Yes' : 'No');
    const {
      files,
      additionalNotes,
      documentType,
      audienceType,
      isInvolved,
      isCreatedByYourself,
      explainDocument,
    } = props;
    const variables = {
      type: regulatory ? FormType.MMR : FormType.MMAT,
      content: [
        {
          question: MMAT_FORM_LABELS.DOCUMENT_TYPE,
          input: get(documentType, 'label'),
          key: 'documentType',
          value: JSON.stringify(documentType),
        },
        {
          question: MMAT_FORM_LABELS.WHO_IS_THE_AUDIENCE,
          input: buildAudienceTypeList(audienceType),
          key: 'audienceType',
          value: JSON.stringify(audienceType),
        },
        {
          question: MMAT_FORM_LABELS.WAS_THE_CLIENT_INVOLVED,
          input: mapYesNoToString(isInvolved),
          key: 'isInvolved',
          value: JSON.stringify(isInvolved),
        },
        {
          question: MMAT_FORM_LABELS.DID_YOU_CREATE_THIS_BY_YOURSELF,
          input: mapYesNoToString(isCreatedByYourself),
          key: 'isCreatedByYourself',
          value: JSON.stringify(isCreatedByYourself),
        },
        {
          question: MMAT_FORM_LABELS.EXPLAIN_DOCUMENT,
          input: explainDocument,
          key: 'explainDocument',
          value: JSON.stringify(explainDocument),
        },
      ],
      dealId: !regulatory ? dealId : null,
      additionalNotes,
      files: files,
    };
    return variables;
  };

  const onSubmitMarketingMaterialForm = async (): Promise<void> => {
    const { values } = form;
    await createFormMutation({
      variables: buildMMForm(values, dealId),
    });
  };

  return { form, onSubmitMarketingMaterialForm, submitting };
};
