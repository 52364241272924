// vendor
import React, { useContext } from 'react';

// Components
import { FormFilePicker, TextArea, FormGroup } from 'app/common/components';

import { MMLabels } from './MarketingMaterialFormConstants';
import {
  MarketingMaterialForm as MarketingMaterialFormType,
  MarketingMaterialHandlerContext,
} from './MarketingMaterialFormHandler';
import { formControlChangeHandlers } from '../../FormControlChangeHandlers/FormControlChangeHandlers';

const MarketingMaterialForm = () => {
  const { form } = useContext(MarketingMaterialHandlerContext);
  const { values, setFieldValue, errors } = form;

  const { handleFieldChange, handleFilesChange } = formControlChangeHandlers<
    MarketingMaterialFormType
  >(setFieldValue);

  return (
    <>
      <FormFilePicker
        label={MMLabels.FILE_UPLOAD}
        files={values.files}
        setFiles={handleFilesChange('files')}
        error={errors.files ? errors.files : ''}
        acceptedFiles=".jpg, .jpeg, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .png, image/jpeg, image/png, text/plain, .doc, .docx, .xls, .xlsx, .ppt, .pptx, application/excel, application/vnd.ms-excel, application/x-excel, application/x-msexcel, application/doc, application/ms-doc, application/msword, application/pdf, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation"
        description="DOC, DOCX, JPG, JPEG, PDF, PNG, PPT, PPTX, XLS, XLSX and plain text are supported."
      />
      <FormGroup label="Do you want to add any comments / notes:">
        <TextArea
          placeholder="Comments..."
          value={values.note}
          onChange={handleFieldChange('note')}
        />
      </FormGroup>
    </>
  );
};

export default MarketingMaterialForm;
