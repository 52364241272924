import React, { useEffect, useState, createContext, useContext, useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';

import { useQuery } from '@apollo/client';
import { UserContext } from 'app/users/context/UserContext';

import { EFormStatus } from 'app/compliance/types/formsTypes';
import { FormType } from 'types/graphql-types';
import { GET_FORMS } from 'api/graphql/compliance/queries';
import { ERoutePatterns } from 'app/core-tools/due-diligence/types/types';
import { EPlatformRole } from 'app/compliance/types/usersTypes';
import { useFeatureFlags } from 'app/utils/hooks/hooks';
import { EFeatureFlag } from 'app/FeatureFlag';
import { ONBOARDING_DATE } from '../constants/constants';

export type u4FormType = {
  id: string;
  code: string;
  type: FormType;
  status: EFormStatus;
  isActive: boolean;
  content: { question: string; input: any; key: string | null; value: string | null }[];
};

export type FormData = {
  forms: u4FormType[];
};

const useFormU4 = () => {
  const user = useContext(UserContext);
  const isCustomer = user && user.isCustomer;
  const onboardingFF = useFeatureFlags(EFeatureFlag.CJ_ONBOARDING);
  const loadingUser = user.loadingProfileData;
  const [blockHub, setBlockHub] = useState(false);
  const [u4Form, setU4Form] = useState<FormData>();
  const role = user.user.platformRole;
  const userCreation = user.createdAt;
  const { data: formData, loading: loadingU4Data } = useQuery<FormData>(GET_FORMS, {
    skip: !user || !user.id,
    variables: {
      searchQuery: {
        generalFilter: [
          {
            field: 'type',
            value: FormType.U4,
          },
        ],
        userId: user.id,
      },
    },
  });

  const u4FormStatus = formData && formData.forms && formData.forms[0] && formData.forms[0].status;

  const loadingU4 = useMemo(() => {
    return loadingU4Data || loadingUser;
  }, [loadingU4Data, loadingUser]);

  // check form has data
  const hasFormU4Data = (data: FormData) => data && !isEmpty(data.forms);

  // check if user is in /onboarding
  const isInOnboarding = (path: string) => path.includes(ERoutePatterns.ONBOARDING);

  const handleFormStatus = (status: EFormStatus) => {
    switch (status) {
      case EFormStatus.DRAFT:
        if (!isInOnboarding(window.location.pathname)) {
          window.location.href = ERoutePatterns.ONBOARDING;
        }
        return '';
      case EFormStatus.REQUEST_FOR_INFORMATION:
        if (!isInOnboarding(window.location.pathname)) {
          window.location.href = ERoutePatterns.ONBOARDING;
        }
        return setBlockHub(true);
      case EFormStatus.APPROVED:
        return setBlockHub(false);
      case EFormStatus.APPROVED_WITH_CONDITIONS:
        return setBlockHub(false);
      default:
        return setBlockHub(true);
    }
  };
  const creationDate = new Date(userCreation);

  // We use ONBOARDING_DATE so that the users that are created after this date will have to do the onboarding process.
  const dateLimitNewUser = new Date(ONBOARDING_DATE);
  const isNewUser = creationDate.getTime() >= dateLimitNewUser.getTime();

  useEffect(() => {
    if (!loadingU4Data && !loadingUser && onboardingFF && isNewUser) {
      if (isCustomer && role === EPlatformRole.USER && formData) {
        if (hasFormU4Data(formData)) {
          handleFormStatus(formData.forms[0].status);
        }
        // if user doesnt have U4 and his location is not in onboarding, redirect to /onboarding
        else if (!isInOnboarding(window.location.pathname)) {
          window.location.href = ERoutePatterns.ONBOARDING;
        }
        setU4Form(formData);
      }
    }
    // Add location.pathname in order to check in every change path
  }, [formData, isCustomer, role, u4Form, window.location.pathname]);

  const u4inReview = useMemo(() => {
    if (u4FormStatus && !loadingU4) {
      return [
        EFormStatus.DRAFT,
        EFormStatus.IN_REVIEW,
        EFormStatus.REQUEST_FOR_INFORMATION,
      ].includes(u4FormStatus);
    }
    return false;
  }, [u4FormStatus, loadingU4]);

  const isU4Approved = [EFormStatus.APPROVED, EFormStatus.APPROVED_WITH_CONDITIONS].includes(
    u4FormStatus || EFormStatus.DRAFT
  );

  return { u4Form, loadingU4, blockHub, u4FormStatus, u4inReview, isU4Approved };
};

const FormsU4ContextDefaults = {
  u4Form: {},
  loadingU4: true,
  blockHub: false,
  u4inReview: false,
  isU4Approved: false,
  u4FormStatus: EFormStatus.DRAFT,
};

export type FormsU4Type = ReturnType<typeof useFormU4>;
export type FormsU4TypeFallback = typeof FormsU4ContextDefaults;
export type FormsU4ContextType = FormsU4Type | FormsU4TypeFallback;

const FormsU4Context = createContext<FormsU4ContextType>(FormsU4ContextDefaults);
const U4ContextRealProvider = FormsU4Context.Provider;

const FormsContextU4Provider = ({ children }: { children: React.ReactNode }) => {
  const formsHandler = useFormU4();
  return <U4ContextRealProvider value={formsHandler}>{children}</U4ContextRealProvider>;
};
export { useFormU4, FormsContextU4Provider, FormsU4Context };
