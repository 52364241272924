import * as yup from 'yup';
import validateFileExtension, { validateFileName } from '../../../../utils/helpers/formValidators';

export const validationSchema = yup.object().shape({
  files: yup
    .array()
    .required('At least one file is required')
    .test('', 'Some file has an invalid name', validateFileName)
    .test('', 'Some file has an invalid extension', validateFileExtension),
});
