import * as yup from 'yup';

export const REQUIRED_MESSAGE = 'This field is required';
export const INVALID_URL_MESSAGE = 'Please enter a valid website';
export const SUPPORTED_FILENAME_VALIDATION = /^[a-zA-Z0-9-_.() ]+$/;

/**
 * Checks if the extension of the files is one of the accepted ones.
 * @param files value representing the files uploaded by the user and to be validated
 * @returns true or false depending on the validation
 */
export default function validateFileExtension(files: File[]): boolean {
  const validation = files.every(file => {
    const splitted = file.name.split('.');
    const extension = splitted[splitted.length - 1];
    const acceptedExtensions = [
      'jpg',
      'jpeg',
      'docx',
      'doc',
      'png',
      'pdf',
      'txt',
      'ppt',
      'pptx',
      'xls',
      'xlsx',
      'JPG',
      'JPEG',
      'DOCX',
      'DOC',
      'PNG',
      'PDF',
      'TXT',
      'PPT',
      'PPTX',
      'XLS',
      'XLSX',
    ];
    return acceptedExtensions.includes(extension);
  });
  return validation;
}

/**
 * Checks if the name of the files is valid.
 * @param files value representing the files uploaded by the user and to be validated
 * @returns true or false depending on the validation
 */
export const validateFileName = (files: File[]): boolean => {
  const validation = files.every(file => {
    return SUPPORTED_FILENAME_VALIDATION.test(file.name);
  });
  return validation;
};

export const basicRequiredInputValidation = () =>
  yup
    .string()
    .required(REQUIRED_MESSAGE)
    .trim();
