import React, { useContext, ReactNode, useState, SetStateAction, Dispatch } from 'react';
import {
  UsersIcon,
  PencilIcon,
  EButtonSizeType,
  DocumentIcon,
  ArrowIcon,
  EDirections,
  FileRoomIcon,
} from '@dealsyte/poki';

import { ERoutePatterns, DealSide, Role } from 'app/core-tools/due-diligence/types/types';
import { UserContext } from 'app/users/context/UserContext';
import { useFeatureFlags } from 'app/utils/hooks/hooks';
import EventService from 'utils/socket/EventService';
import { EFeatureFlag } from 'app/FeatureFlag';
import VDRDealContentSidebarSubsection from './components/VDRSubsection/VDRSubsection';
import CreateFileroomModal from './components/CreateFileroomModal/CreateFileroomModal';
import DealContentSidebarHeader from './components/DealContentSidebarHeader/DealContentSidebarHeader';

import { ESections } from './TypedDealContentSidebar';
import {
  MainContainer,
  CollapsableSubsectionsContainer,
  SectionSeparatorHr,
  LinkContainer,
  Link,
  Anchor,
  CreateFileroomButton,
  ExpandSectionButton,
} from './StyledDealContentSidebar';

import _get from 'lodash/get';

/**
 * Renders an specific deal content (QnA and VDR) in a sidebar format.
 * @param setShowSettingsModal - Dispatch function that toggles SettingsModal component display.
 */
function DealContentSidebar({
  setShowSettingsModal,
}: {
  setShowSettingsModal: Dispatch<SetStateAction<boolean>>;
}): JSX.Element {
  const user = useContext(UserContext);

  const [activeSections, setActiveSections] = useState<ESections[]>([ESections.VDR]);

  const [showCreateFileroomModal, setShowCreateFileroomModal] = useState(false);

  const featureFlags = useFeatureFlags([EFeatureFlag.COMPLIANCE]);

  /**
   * Handle active sections changes. Updates the activeSection state according to the clicked
   * section.
   * @param clickedSection The section that is being clicked.
   */
  function handleActiveSectionChange(clickedSection: ESections): void {
    if (!!activeSections.find(section => section === clickedSection)) {
      return setActiveSections(activeSections.filter(section => section !== clickedSection));
    }

    return setActiveSections([...activeSections, clickedSection]);
  }

  const sections: {
    label: string | ReactNode;
    link?: ERoutePatterns;
    icon?: ReactNode;
    bolder?: Boolean;
    show: boolean;
    topSeparator?: boolean;
    subsections?: { label: string; link: ERoutePatterns }[] | ReactNode;
  }[] = [
    {
      label: (
        <LinkContainer style={{ position: 'relative' }}>
          <UsersIcon height={18} style={{ marginLeft: 8 }} color="#445A72" />

          <Anchor
            onClick={() => {
              setShowSettingsModal(true);
              EventService.triggerEvent('MODAL_SHOW', ['deal_settings']);
            }}
            style={{ marginLeft: 16 }}
          >
            Invite members
          </Anchor>
        </LinkContainer>
      ),
      show: user.side === DealSide.SELL && user.role === Role.ADMIN,
    },
    {
      label: 'Compliance',
      link: ERoutePatterns.COMPLIANCE_TRACKER,
      bolder: true,
      // @ts-ignore
      show: (featureFlags[EFeatureFlag.COMPLIANCE] as boolean) && _get(user, 'isCustomer'),
      icon: <PencilIcon height={18} style={{ marginLeft: 8 }} color="#445A72" />,
    },
    {
      label: 'Document Bank',
      link: ERoutePatterns.DOCUMENT_BANK,
      bolder: true,
      // @ts-ignore
      show: user.side === DealSide.SELL,
      icon: <DocumentIcon height={18} style={{ marginLeft: 8 }} color="#445A72" />,
    },
    {
      label: (
        <LinkContainer style={{ position: 'relative' }}>
          <FileRoomIcon style={{ marginLeft: 8 }} />

          <Link
            to={ERoutePatterns.VDR}
            style={{ marginLeft: 16, fontWeight: 'bolder' }}
            activeStyle={{
              color: !!activeSections.find(activeSection => activeSection === ESections.VDR)
                ? '#304154'
                : '#4585EB',
            }}
          >
            Data Rooms
          </Link>

          <ExpandSectionButton
            onClick={() => handleActiveSectionChange(ESections.VDR)}
            size={EButtonSizeType.small}
          >
            <ArrowIcon
              direction={
                !!activeSections.find(activeSection => activeSection === ESections.VDR)
                  ? EDirections.right
                  : EDirections.down
              }
            />
          </ExpandSectionButton>

          {user.role === Role.ADMIN && user.side === DealSide.SELL && (
            <CreateFileroomButton
              size={EButtonSizeType.small}
              onClick={() => {
                setShowCreateFileroomModal(true);
                EventService.triggerEvent('MODAL_SHOW', ['create_vdr_settings']);
              }}
            >
              +
            </CreateFileroomButton>
          )}
        </LinkContainer>
      ),
      show: !!user.activeDeal,
      topSeparator: true,
      subsections: (
        <CollapsableSubsectionsContainer
          maxHeight={!!activeSections.find(section => section === ESections.VDR) ? 600 : 0}
        >
          <VDRDealContentSidebarSubsection />
        </CollapsableSubsectionsContainer>
      ),
    },
  ];

  return (
    <>
      <MainContainer>
        <DealContentSidebarHeader setShowSettingsModal={setShowSettingsModal} />

        {sections.map((section, index) => {
          return section.show ? (
            <div key={index} style={{ marginTop: index === 0 ? 16 : 'unset' }}>
              {section.topSeparator && <SectionSeparatorHr style={{ marginTop: 0 }} />}

              {typeof section.label === 'string' ? (
                <LinkContainer>
                  {section.icon}

                  <Link
                    to={section.link || ''}
                    style={{
                      marginLeft: section.icon ? 16 : 38,
                      fontWeight: section.bolder ? 'bolder' : 'inherit',
                    }}
                  >
                    {section.label}
                  </Link>
                </LinkContainer>
              ) : (
                section.label
              )}

              {section.subsections &&
                (Array.isArray(section.subsections)
                  ? (section.subsections as any[]).map((subsection, index) => {
                      return (
                        <Link key={index} to={subsection.link || ''} style={{ marginLeft: 62 }}>
                          {subsection.label}
                        </Link>
                      );
                    })
                  : section.subsections)}
            </div>
          ) : null;
        })}
      </MainContainer>

      <CreateFileroomModal show={showCreateFileroomModal} setShow={setShowCreateFileroomModal} />
    </>
  );
}

export default DealContentSidebar;
