export const DocumentsTypesOptions = [
  {
    label: 'Confidential information memorandum',
    value: 'CONFIDENTIAL_INFORMATION_MEMORANDUM',
  },
  {
    label: 'Marketing deck',
    value: 'MARKETING_DECK',
  },
  {
    label: 'Marketing teaser',
    value: 'MARKETING_TEASER',
  },
  {
    label: 'Private placement memorandum',
    value: 'PRIVATE_PLACEMENT_MEMORANDUM',
  },

  {
    label: 'Other',
    value: 'OTHER',
  },
];

export const AudienceOptions = [
  {
    label: 'Institutional investors',
    value: 'INSTITUTIONAL_INVESTORS',
    description: 'Either entities or individuals with more than $50M in assets.',
  },
  {
    label: 'Entity',
    value: 'ENTITY',
    description:
      'The investor is an entity with less than $50M but with more than $5M in assets, which was not formed for the purpose of investing in this offering.',
  },
  {
    label: 'Natural persons',
    value: 'NATURAL_PERSONS',
    description:
      "An individual who meets the 'accredited investor' definition with less than $50M in assets.",
  },
];

export const MMAT_FORM_LABELS = {
  DOCUMENT_TYPE: 'Document type',
  WHO_IS_THE_AUDIENCE: 'Who is the audience?',
  WAS_THE_CLIENT_INVOLVED: 'Was the client involved in creating this marketing material?',
  DID_YOU_CREATE_THIS_BY_YOURSELF: 'Did you create this marketing material yourself?',
  EXPLAIN_DOCUMENT: 'Explain how the document was created',
};
