import styled from 'styled-components';

const MainContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  cursor: pointer;
`;

const Header = styled.h1`
  color: #304154;
  font-size: ${({ theme }) => theme.fontSizes.regular};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  letter-spacing: 0.28px;
  line-height: 17px;
  margin-right: 8px;
  max-width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export { MainContainer, Header };
