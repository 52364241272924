import * as yup from 'yup';
import { ELoginErrors } from './Login/TypedLogin';

function equalTo(ref: any, msg: any) {
  return this.test({
    name: 'equalTo',
    exclusive: false,
    // eslint-disable-next-line
    message: msg || '${path} must be the same as ${reference}',
    params: {
      reference: ref.path,
    },
    test(value: any) {
      return value === this.resolve(ref);
    },
  });
}

function differentThan(ref: any, msg: any) {
  return this.test({
    name: 'equalTo',
    exclusive: false,
    // eslint-disable-next-line
    message: msg || '${path} must be the same as ${reference}',
    params: {
      reference: ref.path,
    },
    test(value: any) {
      return value !== this.resolve(ref);
    },
  });
}

yup.addMethod(yup.string, 'equalTo', equalTo);
yup.addMethod(yup.string, 'differentThan', differentThan);

export const userAccountSettingsValidationSchema = yup.object().shape({
  email: yup.string().email(ELoginErrors.INVALID_EMAIL),
  firstName: yup
    .string()
    .trim()
    .matches(/^[a-zA-Z ]*$/, 'Only alphabets are allowed')
    .required('This field is required'),
  lastName: yup
    .string()
    .trim()
    .matches(/^[a-zA-Z ]*$/, 'Only alphabets are allowed')
    .required('This field is required'),
});

export const userEnableMfaValidationSchema = yup.object().shape({
  currentPassword: yup.string().required(ELoginErrors.EMPTY_PASSWORD),
  mfaToken: yup
    .string()
    .length(6, ELoginErrors.INVALID_MFA_LENGTH)
    .required(ELoginErrors.EMPTY_MFA_CODE),
});

export const userDisableMfaValidationSchema = yup.object().shape({
  currentPassword: yup.string().required(ELoginErrors.EMPTY_PASSWORD),
});

export const userEnableMfaWithoutPasswordValidationSchema = yup.object().shape({
  mfaToken: yup
    .string()
    .length(6, ELoginErrors.INVALID_MFA_LENGTH)
    .required(ELoginErrors.EMPTY_MFA_CODE),
});

export const userPasswordSettingsValidationSchema = yup.object().shape({
  newPassword: yup.string().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/),
  verifiedNewPassword: yup
    .string()
    .required('Please verify your new password.')
    //@ts-ignore
    .equalTo(yup.ref('newPassword'), 'Passwords do not match.'),
});

export const localLoginFormValidationSchema = yup.object().shape({
  email: yup
    .string()
    .required(ELoginErrors.EMPTY_EMAIL)
    .email(ELoginErrors.INVALID_EMAIL),
});

export const mfaTokenFormValidationSchema = yup.object().shape({
  token: yup
    .string()
    .required(ELoginErrors.EMPTY_MFA_CODE)
    .length(6, ELoginErrors.INVALID_MFA_LENGTH),
});
