import React, { Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import FeatureFlag, { EFeatureFlag } from 'app/FeatureFlag';

import { ERoutePatterns, PlatformRole } from 'app/core-tools/due-diligence/types/types';

// Helpers
import getUserToken from 'app/utils/helpers/getUserToken';

// Components
import { Loader } from 'app/common/components';
import { CustomerRoute } from '../CustomerRoute';
import { Layout } from '../../layout/Layout';
import { MainConsumer } from '../../providers/MainConsumer';
import { ContextProviders } from '../../providers/ContextProviders';

// Views
const AttestationView = lazy(() => import('../../compliance/sections/Attestation/Routes'));
const ComplianceTracker = lazy(() =>
  import('../../compliance/sections/ComplianceTracker/ComplianceTracker')
);
const DealRegulationView = lazy(() =>
  import('../../compliance/sections/DealRegulation/DealRegulationRoutes')
);
const DocumentBank = lazy(() => import('../../compliance/sections/DocumentBank/DocumentBank'));
const DueDiligence = lazy(() => import('../../core-tools/due-diligence/DueDiligence'));
const FormDetails = lazy(() => import('../../compliance/sections/FormDetails/FormDetails'));
const FeatureFlaggedHome = lazy(() =>
  import('../../home/homev2/components/HomeComponents/FeatureFlaggedHome')
);
const FormsBrowser = lazy(() => import('../../compliance/sections/FormsBrowser/FormsBrowser'));
const MarketplaceRoutes = lazy(() => import('../../marketplace'));
const MyAccountView = lazy(() => import('../../my-account/MyAccountRoutes'));
const MyDeals = lazy(() => import('../../home/homev2/MyDeals'));
const OnboardingView = lazy(() => import('../../onboarding/Onboarding'));
const RegulatoryProfileFormsBrowser = lazy(() =>
  import('../../compliance/sections/FormsBrowser/RegulatoryProfileFormsBrowser')
);

function PrivateRoutes() {
  const authToken = getUserToken();

  if (!authToken) return null;

  const dueDiligencePaths = [ERoutePatterns.FAQ, ERoutePatterns.VDR, ERoutePatterns.NOTIFICATIONS];

  return (
    <ContextProviders>
      <Layout>
        <MainConsumer>
          {({
            userContext: { loadingProfileData, isImpersonated, isCustomer, platformRole },
            formsU4Context: { u4inReview },
          }) =>
            loadingProfileData ? (
              <Loader />
            ) : (
              <Suspense fallback={<div />}>
                <Switch>
                  <Route path={ERoutePatterns.ONBOARDING} component={OnboardingView} />
                  <Route path={dueDiligencePaths} component={DueDiligence} />
                  <Route path={ERoutePatterns.HOME} component={FeatureFlaggedHome} />

                  <CustomerRoute
                    path={ERoutePatterns.REGULATORY_PROFILE_TRACKER}
                    component={RegulatoryProfileFormsBrowser}
                  />
                  <CustomerRoute
                    path={`${ERoutePatterns.COMPLIANCE_TRACKER}/form/:formId`}
                    component={FormDetails}
                  />

                  <Route
                    path={ERoutePatterns.DOCUMENT_BANK}
                    component={
                      (isCustomer && !u4inReview) ||
                      platformRole === PlatformRole.DEAL_FLOW_LAUNCH ||
                      platformRole === PlatformRole.MARKETPLACE_ONLY_USER
                        ? DocumentBank
                        : () => <Redirect to="/" />
                    }
                  />

                  {<Route path={ERoutePatterns.CONNECT} component={MarketplaceRoutes} />}
                  <CustomerRoute path={ERoutePatterns.MY_DEALS} component={MyDeals} />
                  <CustomerRoute
                    condition={!isImpersonated}
                    path={ERoutePatterns.ATTESTATION}
                    component={AttestationView}
                  />
                  <Route path={ERoutePatterns.MY_ACCOUNT} component={MyAccountView} />
                  <Route
                    path={[
                      `${ERoutePatterns.DEAL_REGULATION}/:dealId/:formType`,
                      `${ERoutePatterns.DEAL_REGULATION}/:dealId`,
                      ERoutePatterns.DEAL_REGULATION,
                    ]}
                    component={DealRegulationView}
                  />
                  <FeatureFlag
                    feature={EFeatureFlag.COMPLIANCE_TRACKER_V2}
                    fallback={
                      <CustomerRoute
                        exact
                        path={`${ERoutePatterns.COMPLIANCE_TRACKER}/:dealId`}
                        component={FormsBrowser}
                      />
                    }
                  >
                    <CustomerRoute
                      path={`${ERoutePatterns.COMPLIANCE_TRACKER}/:dealId`}
                      component={ComplianceTracker}
                    />
                  </FeatureFlag>
                </Switch>
              </Suspense>
            )
          }
        </MainConsumer>
      </Layout>
    </ContextProviders>
  );
}

export default PrivateRoutes;
