import { IUser } from './usersTypes';

export enum EFormStatus {
  APPROVED_WITH_CONDITIONS = 'APPROVED_WITH_CONDITIONS',
  IN_REVIEW = 'IN_REVIEW',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
  REQUEST_FOR_INFORMATION = 'REQUEST_FOR_INFORMATION',
  PASS_TO_LEGAL_TEAM = 'PASS_TO_LEGAL_TEAM',
  CLOSED_DUPLICATED = 'CLOSED_DUPLICATED',
  CLOSED = 'CLOSED',
  REVIEWED_BY_LEGAL = 'REVIEWED_BY_LEGAL',
  REVIEWED_BY_CO = 'REVIEWED_BY_CO',
  DRAFT = 'DRAFT',
}

export enum ENoteType {
  STATUS_CHANGE = 'STATUS_CHANGE',
  NOTE = 'NOTE',
  REVIEWER_ASSIGNED = 'REVIEWER_ASSIGNED',
  REVIEWER_UNASSIGNED = 'REVIEWER_UNASSIGNED',
}

export type INote = {
  id: string;
  type: ENoteType;
  content: string;
  attachments: IFormFile[];
  authorId: string;
  author: IUser;
  createdAt: string;
  updatedAt?: string;
  uploadedBy?: string | undefined;
};

export type IForm = {
  id: string;
  dealId: string;
  updatedAt: string;
  notes: INote[];
  type: EFormType;
  content: IFormContent[];
  status: EFormStatus;
  author: IUser;
  structureId: string;
  entity: IEntity;
  createdAt: string;
  code: string;
  isActive: boolean;
  files?: IFormFile[];
  metrics: IFormMetrics;
  measure: IFormMeasure;
};

export type IFormMeasure = {
  noteCount: number;
  attachmentCount: number;
};

export type IFormMetrics = {
  unattended: {
    beforeTime: number;
    afterTime: number;
  };
  attended: {
    beforeTime: number;
    afterTime: number;
  };
};

export type IFormFile = {
  uploadedBy?: string | undefined;
  name: string;
  filename: string;
  approved?: boolean;
  link: string;
  deleted?: boolean;
  createdAt?: string;
  author?: string;
  size?: number;
  type: string;
  formId: string;
  noteId: string;
};

export type IEntity = {
  id: string;
  name: string;
};

export type IFormContent = {
  question: string;
  input: string | boolean | number | Date;
  key?: string;
};

export enum EFormType {
  AMNel = 'AMN_EL',
  AOQ = 'AOQ',
  CDOC = 'CDOC',
  COMMS = 'COMMS',
  DCR = 'DCR',
  DRA = 'DRA',
  EL = 'EL',
  FSAext = 'FSA_EXT',
  FSAint = 'FSA_INT',
  INV = 'INV',
  KYC = 'KYC',
  KYCcl = 'KYC_CL',
  KYCco = 'KYC_CO',
  MMAT = 'MMAT',
  MMF = 'MMF',
  MMR = 'MMR',
  NDR = 'NDR',
  OBA = 'OBA',
  OBAcct = 'OBAcct',
  PST = 'PST',
  SE = 'SE',
  U4 = 'U4',
}

export enum EYesNoOptions {
  YES = 'Yes',
  NO = 'No',
}

export type IObacctRow = {
  accountOwner: string;
  accountNumber: string;
  financialInstitutionName: string;
  accountType: {
    label: string;
    value: string;
  };
};

export enum EPSTParticipationOptions {
  ADVISOR = 'Advisor',
  FINDER = 'Finder',
  INDEPENDENT_CONTRACTOR = 'Independent Contractor',
  INVESTOR = 'Investor',
  OFFICER = 'Officer',
  OWNER = 'Owner',
  OTHERS = 'Other',
}

export enum EPSTSellingCompensationOptions {
  COMMISSIONS = 'Commissions',
  EXPENSE_REIMBURSEMENTS = 'Expense Reimbursements',
  FINDER_FEES = "Finder's Fees",
  RIGHTS_OF_PARTICIPATION = 'Rights of Participation in profits, tax benefits, or dissolution proceeds, as a general partner or otherwise',
  SECURITIES = 'Securities or Rights to acquire Securities',
  OTHERS = 'Other',
}
