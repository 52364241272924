import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';
import get from 'lodash/get';
import useStateModal from 'app/utils/hooks/useStateModal';

// mutations
import { CREATE_FORM, CREATE_NOTE } from 'api/graphql/compliance/mutations';

// context
import { AlertContext } from 'app/notifications/context/Alert/Alert';
import { DealsContext } from 'app/compliance/context/DealsContext';
import { FormsContext } from 'app/compliance/context/FormsContext';

import { FormType } from '../../../../../types/graphql-types';

// hooks
import { FormikStub, useForm } from 'app/utils/hooks/useForm';

// validation schema
import { validationSchema } from './MarketingMaterialFormValidations';

const initialValues = {
  note: '',
  files: [],
};

export type MarketingMaterialForm = {
  note: string;
  files: File[];
};

export interface UseMarketingMaterialFormHandler {
  form: FormikStub<MarketingMaterialForm>;
  onSubmitMarketingMaterialForm: () => Promise<void>;
  submitting: boolean;
}

export const MarketingMaterialHandlerContext = React.createContext<UseMarketingMaterialFormHandler>(
  {} as UseMarketingMaterialFormHandler
);
export const MarketingMaterialHandlerProvider = MarketingMaterialHandlerContext.Provider;

export const useMarketingMaterialFormHandler = (): UseMarketingMaterialFormHandler => {
  const { showErrorToast, showSuccessToast } = useContext(AlertContext);
  const { refetchAll } = useContext(FormsContext);
  const { selectedDeal: dealId } = useContext<any>(DealsContext);
  const { hide, modalState } = useStateModal('marketingMaterialForm');

  const isEdit = get<boolean>(modalState, 'props.isEdit', false);
  const formId = get<string>(modalState, 'props.formId', '');

  const form = useForm<MarketingMaterialForm>({
    initialValues,
    validationSchema,
  });

  const regulatory = get(modalState, 'props.isRegulatory', false);

  const { resetForm } = form;

  const [createNote, { loading: isNoteSubmitting }] = useMutation(CREATE_NOTE);

  const handleFormUpdate = ({ note, files }: { note: string; files: File[] }) => {
    return createNote({
      variables: {
        input: {
          type: 'NOTE',
          content: note,
          entity: 'FORM',
          entityId: formId,
          attachments: files,
          private: false,
        },
      },
    }) as Promise<unknown>;
  };

  const onSuccess = () => {
    hide();
    resetForm();
    if (regulatory) {
      refetchAll(['regulatoryProfile', 'MMRCount']);
    } else {
      refetchAll(['complianceTracker', 'dealRegulation']);
    }
  };

  const [createFormMutation, { loading: loadingForm }] = useMutation(CREATE_FORM, {
    fetchPolicy: 'no-cache',
    onCompleted() {
      showSuccessToast({ title: 'Success!', description: 'Form was created successfully' });
      onSuccess();
    },
    onError() {
      showErrorToast({ title: 'Error', description: 'An error occurred while saving the form' });
    },
  });

  const submitting = loadingForm || isNoteSubmitting;

  const buildMMForm = (props: MarketingMaterialForm, dealId: string) => {
    const { files, note } = props;
    const variables = {
      type: regulatory ? FormType.MMR : FormType.MMAT,
      content: [],
      dealId: !regulatory ? dealId : null,
      note,
      files: files,
    };
    return variables;
  };

  const onSubmitMarketingMaterialForm = async (): Promise<void> => {
    const { values } = form;
    if (isEdit) {
      try {
        if (values.note || (values.files && values.files.length)) {
          await handleFormUpdate({ note: values.note, files: values.files });
          showSuccessToast({ title: 'Success!', description: 'Form was updated successfully' });
        }
        onSuccess();
      } catch (e) {
        showErrorToast({
          title: 'Error',
          description: 'An error occurred while updating the form',
        });
      }
    } else {
      await createFormMutation({
        variables: buildMMForm(values, dealId),
      });
    }
  };

  return { form, onSubmitMarketingMaterialForm, submitting };
};
