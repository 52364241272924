import React, { useContext } from 'react';

// Context
import { formControlChangeHandlers } from 'app/compliance/components/FormControlChangeHandlers/FormControlChangeHandlers';
import {
  MarketingMaterialHandlerContext,
  MarketingMaterialForm as MMATtype,
} from './MarketingMaterialsFormHandler';

// Components
import {
  FormFilePicker,
  FormGroup,
  Input,
  Select,
  SingleOptionRadioButtons,
  TextArea,
} from '../../../../../common/components';

// Icon
import { FilesIcon } from '../../../../../common/icons';

// Constants
import {
  AudienceOptions,
  DocumentsTypesOptions,
  MMAT_FORM_LABELS,
} from './MarketingMaterialsFormConstants';

// Style
import * as S from './MarketingMaterialsFormStyled';

const MarketingMaterialForm = () => {
  const { form } = useContext(MarketingMaterialHandlerContext);
  const { values, setFieldValue, errors, touched } = form;

  const {
    handleFieldChange,
    handleSelectChange,
    handleYesNoChange,
    handleFilesChange,
  } = formControlChangeHandlers<MMATtype>(setFieldValue);

  const acceptedFiles =
    '.doc, .docx, .xls, .xlsx, application/excel, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/x-excel, application/x-msexcel, application/doc, application/ms-doc, application/msword, application/pdf, .jpeg, .png, image/jpeg, image/png, image/x-png, application/vnd.openxmlformats-officedocument.wordprocessingml.document';

  return (
    <form>
      <S.Text>
        Upload and categorize marketing materials or public communications for compliance
        supervision.
      </S.Text>
      <S.DocumentInfoContainer>
        <div className="icon-container">
          <FilesIcon />
        </div>
        <S.Text>
          For multiple documents, fill out individual forms for each of them. [JPG, JPEG, PNG, DOC,
          DOCX, XLS, XLSX and PDF] are supported.
        </S.Text>
      </S.DocumentInfoContainer>
      <fieldset>
        <S.UploadButtonContainer>
          <FormFilePicker
            displayAs="button"
            files={values.files}
            error={touched.files && errors.files}
            setFiles={handleFilesChange('files')}
            acceptedFiles={acceptedFiles}
          />
        </S.UploadButtonContainer>
        <FormGroup label={MMAT_FORM_LABELS.DOCUMENT_TYPE} required>
          <Select
            inputId="documentType"
            name="documentType"
            maxMenuHeight={200}
            placeholder="Select type"
            isSearchable={false}
            options={DocumentsTypesOptions}
            value={values.documentType}
            onChange={handleSelectChange('documentType')}
          />
        </FormGroup>
        <FormGroup
          label={MMAT_FORM_LABELS.WHO_IS_THE_AUDIENCE}
          required
          error={touched.audienceType && errors.audienceType}
        >
          <Select
            inputId="audienceType"
            name="audienceType"
            maxMenuHeight={200}
            placeholder="Select audience(s)"
            isSearchable={false}
            options={AudienceOptions}
            isMulti
            value={values.audienceType}
            onChange={handleSelectChange('audienceType')}
          />
        </FormGroup>
        <FormGroup label={MMAT_FORM_LABELS.WAS_THE_CLIENT_INVOLVED} required>
          <SingleOptionRadioButtons
            strictValueOnChange
            id="isInvolved"
            name="isInvolved"
            value={values.isInvolved}
            onChange={handleYesNoChange('isInvolved')}
          />
        </FormGroup>
        <FormGroup label={MMAT_FORM_LABELS.DID_YOU_CREATE_THIS_BY_YOURSELF} required>
          <SingleOptionRadioButtons
            strictValueOnChange
            id="isCreatedByYourself"
            name="isCreatedByYourself"
            value={values.isCreatedByYourself}
            onChange={handleYesNoChange('isCreatedByYourself')}
          />
        </FormGroup>
        {values.isCreatedByYourself === false && (
          <FormGroup
            id="explainDocument"
            required
            showCharCount
            label={MMAT_FORM_LABELS.EXPLAIN_DOCUMENT}
            error={touched.explainDocument && errors.explainDocument}
          >
            {ref => (
              <Input
                ref={ref as any}
                id="explainDocument"
                maxLength={140}
                name="explainDocument"
                placeholder="Write your answer"
                value={values.explainDocument}
                onChange={handleFieldChange('explainDocument')}
              />
            )}
          </FormGroup>
        )}
        <FormGroup id="additionalNotes" optional showCharCount label="Additional notes">
          {ref => (
            <TextArea
              className="textArea"
              ref={ref as any}
              placeholder="Write your notes"
              id="additionalNotes"
              maxLength={400}
              name="additionalNotes"
              value={values.additionalNotes}
              onChange={handleFieldChange('additionalNotes')}
            />
          )}
        </FormGroup>
      </fieldset>
    </form>
  );
};

export default MarketingMaterialForm;
